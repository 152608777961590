require('./frontend/ToggleNav');
require('./frontend/ScrollClass');
require('./frontend/CarouselReviews');
require('./frontend/StickyTopbar');
require('./frontend/minicart');
require('./frontend/Glightbox');
require('./frontend/countup');
require('./frontend/ecomail');

// Fix for strange URLs on thank you page
window.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('a[data-rewrite]').forEach(function (link) {
    link.setAttribute('href', link.dataset.rewrite);
  })
});
